<template>
	<div class="details">
		<div class="title">
			<div class="title-logo">
				<img src="../assets/image/logo2.png" >
			</div>
			<div class="title-left">
				<span>
					<router-link to="./" class="tabItem" exact>首页</router-link>
				</span>
				<span v-for="(item,index) in industryInfos" :key="index"  class="tabItem"
				@click="hang(index,item.industryId)">
					{{item.industryName}}
				</span>
				<span>
					<router-link to="./pj-login" class="tabItem">企业服务</router-link>
				</span>
				<span>
					<router-link to="./pj-about" class="tabItem">关于我们</router-link>
				</span>
			</div>
		</div>
		<div class="details-content">
			<div class="details-content-main">
				<div class="details-content-title">
					<div class="details-content-title-left">
						<div class="details-content-title-left-tag">
							招聘中
						</div>
						<div class="details-content-title-left-zhi">
							{{detailObj.postTitle}}
						</div>
						<div class="details-content-title-left-inf">
							<span v-if="detailObj.province && detailObj.city && detailObj.district">
								{{pcaaProvince(detailObj.province)}}
								{{pcaaCity(detailObj.province,detailObj.city)}}
								{{pcaaDistrict(detailObj.city,detailObj.district)}}
							</span>
							<span>{{detailObj.expRequire}}</span>
							<span>{{detailObj.eduRequire}}</span>
						</div>
					</div>
					<div class="details-content-title-right">
						<span v-if="detailObj.salaryType">{{price(detailObj.salaryType)}}</span>
					</div>
				</div>
				<div class="company" @click="company(pubUser.companyId)">
					<div class="content-main-item-right">
						<div class="item-right-header">
							<img :src="pubUser.headImg">
						</div>
						<div class="item-right-main">
							<div class="item-right-main-top">
								{{pubUser.nickName}}・<span>hr</span>
							</div>
							<div class="item-right-main-bottom">
								<span style="margin-right: 10px;">{{pubUser.companyName}}</span>
								<span>{{detailObj.scale}}</span>
							</div>
						</div>
					</div>
					<div class="more-img">
						<img src="../assets/image/gengduo.png">
					</div>
				</div>
				<div class="detail-informate">
					<div class="detail-informate-left">
						<div class="detail-informate-left-item">
							<span style="margin-left: .1rem;">工作描述</span>
							<div class="item-main">
								<pre>{{detailObj.workContent}}。
				</pre>
							</div>
						</div>
						
						<div class="detail-informate-left-item">
							<span style="margin-left: .1rem;">公司信息</span>
							<div class="item-main comte">
								{{detailObj.introduce}}											
							</div>
						</div>
					</div>
					<div class="detail-informate-center">

					</div>
					<div class="detail-informate-right">
						<div class="details-content-right-title">
							{{pubUser.companyName}}
						</div>
						<div class="details-content-right-main">
							公司规模：{{detailObj.scale}} <br>
							公司地址：{{detailObj.address}}<br>
							注册时间：{{detailObj.estTime}}<br>
							注册资本：{{detailObj.capital}}万元<br>
							经营范围：{{detailObj.scope}}<br>
							联系方式：{{detailObj.contact}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from '../components/common/Footer.vue'
	import $http from '../common/api/axios.js'
	import {
		postDetail,industryInfo
	} from '../common/api/api.js'
	import {
		pcaa
	} from 'area-data';
	//import Bottom from './common/Bottom.vue'
	export default {
		data() {
			return {
				detailObj: {},
				pubUser: {},
				industryInfos:[],
			}
		},
		components: {
			Footer
		},
		methods: {
			hang(index, id) {
				this.$router.push({path:'/pj-information',query: {value:id,currcet:index}})
			},
			price(salaryType) {
				let xinoptions = this.$store.state.xinoptions;
				return xinoptions[salaryType].label
			},
			pcaaProvince(province) {
				let num1 = 86
				let num2 = province
				return pcaa[num1][num2]
			},
			pcaaCity(province, city) {
				let num1 = province
				let num2 = city
				return pcaa[num1][num2]

			},
			pcaaDistrict(city, district) {
				let num1 = city
				let num2 = district
				return pcaa[num1][num2]
			},
			company(companyId) {
				let id = companyId
				this.$router.push({
					path: '/pj-company',
					query: {
						id: id
					}
				})
			}
		},
		created() {
			let that = this
			let id = this.$route.query.id
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url: industryInfo,
				}).then((res) => {
					that.industryInfos = res.data.industryInfos
				}).catch(() => {
					
				})
				$http.request({
					url: postDetail,
					data: {
						"postId": id
					}
				}).then((res) => {
					that.detailObj = res.data
					that.pubUser = res.data.pubUser
				}).catch(() => {

				})
			})
		}
	}
</script>

<style scoped>
	.title-left{
		margin-left: 1.45rem;
	}
	.title-left span {
		margin-left: .64rem;
	}
	.tabItem {
		padding: 10px 0;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 600;
		color: #333333;
		text-decoration:none
	}
	.router-link-active {
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: bold;
		color:#E41D1D;
		border-bottom: .04rem solid #E41D1D;
	}
	
	.title {
		display: flex;
		align-items: center;
		width: 100%;
		height: .72rem;
		z-index: 999;
		font-size: .16rem;
		font-family: PingFang SC;
		color: #FFFFFF;
		cursor: pointer;
		box-shadow: 0px 3px 6px rgba(172, 172, 172, 0.1);
		transform: translate3d(0, 0, 0);
	}
	.title-logo{
		margin-left: 3.6rem;
	}
	.title-logo img{
		width: 1.5rem;
	}
	.item-right-main {
		padding-top: .05rem;
	}

	.item-right-main-bottom span {
		font-size: .14rem;
		color: #666666;
	}

	.item-right-main-top {
		padding-top: .1rem;
		margin-bottom: .16rem;
		font-size: .16rem;
		font-weight: 600;
		color: #333333;
	}

	.item-right-main-top>span {
		font-size: .16rem;
		font-weight: 400;
		color: #666666 !important;
	}

	.item-right-header>img {
		width: .76rem;
		border-radius: .38rem;
	}

	.item-right-header {
		margin-right: .18rem;
	}

	.content-main-item-right {
		display: flex;
	}

	.company {
		position: relative;
		box-sizing: border-box;
		padding-top: .36rem;
		padding-left: .2rem;
		width: 12rem;
		height: 1.5rem;
		border-bottom: 1px solid #B0BBD1;
		cursor: pointer;
	}

	.company>img {
		width: .76rem;
	}

	.details-content-title-right {
		position: relative;
		width: 100%;
	}

	.details-content-title-right span {
		position: absolute;
		right: 0.45rem;
		top: .88rem;
		font-size: .30rem;
		font-weight: 600;
		color: #E41D1D;
	}

	.details-content-title-left-inf span {
		margin-right: .4rem;
		font-size: .14rem;
		font-weight: 300;
		color: #666666;
	}

	.details-content-title-left-zhi {
		margin-bottom: .2rem;
		font-size: .30rem;
		font-weight: 600;
		color: #333333;
	}

	.details-content-title-left-tag {
		margin-bottom: .2rem;
		font-size: .14rem;
		color: #666666;
	}

	.details-content-title-left {
		width: 7rem;
		padding-top: .54rem;
		padding-left: .2rem;
	}

	.details-content {
		padding: 0 3.6rem;
		padding-bottom: .88rem;
		font-family: PingFang SC;
	}

	.details-content-main {
		padding-bottom: .38rem;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
	}

	.details-content-title {
		display: flex;
		width: 12rem;
		height: 2.07rem;
		background: #E9EBEF;
	}

	.more-img {
		position: absolute;
		right: 0.82rem;
		top: 0.6rem
	}

	.more-img img {
		width: .26rem;
	}

	.title-right-login {
		display: flex;
		align-items: center;
	}

	.title-right-login img {
		width: .39rem;
	}

	.title-right-login span {
		margin-left: .12rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.title-right {
		margin-left: .65rem;
	}

	.detail-informate {
		display: flex;
		width: 12rem;
		font-family: PingFang SC;
	}

	.detail-informate-left {
		box-sizing: border-box;
		padding-top: .44rem;
		padding-left: .20rem;
		width: 7.44rem;
	}

	.detail-informate-center {
		margin-top: .24rem;
		width: 0px;
		height: 6.3rem;
		border-right: 1px dashed #C3C3C3;
	}

	.detail-informate-right {
		width: 4.58rem;
	}

	.detail-informate-left-item>span {
		font-size: .18rem;
		font-weight: 600;
		color: #333333;
	}

	.item-main {
		padding-left: .1rem;
		padding-top: .18rem;
	}

	pre {
		white-space: pre-wrap;
		word-wrap: break-word;
		font-size: .15rem;
		font-family: PingFang SC;
		color: #666666;
		line-height: .3rem;
	}
	.comte{
		padding-right: .3rem;
		font-size: .15rem;
		font-weight: 400;
		line-height: 30px;
		color: #666666;
	}
	.detail-informate-right{
		padding-top: .44rem;
		padding-left: .25rem;
		padding-right: .25rem;
	}
	.details-content-right-title{
		margin-bottom: .2rem;
		font-size: .18rem;
		font-weight: 600;
		color: #333333;
	}
	.details-content-right-main{
		font-size: .15rem;
		font-weight: 400;
		line-height: 30px;
		color: #666666
	}
</style>
